<template>
  <div ref="cc" class="container-custom">
	<Header />

    <div class="step step--chose text-center">
      <h1 class="title title--md">Alege care este dispozitivul tău</h1>

      <div class="device device--1" @click="redirect('iqos-3')">
        <img class="device__img device__img--1" src="@/assets/chose/device-1.png" />
        <div class="device__info device__info--1">
          <p class="text-uppercase mb-2">iqos 3</p>
          <router-link :to="{name: 'iqos-3'}" class="btn btn--primary btn--lg">Alege</router-link>
        </div>
      </div>
      <div class="device device--2" @click="redirect('iqos-multi')">
        <img class="device__img device__img--2" src="@/assets/chose/device-2.png" />
        <div class="device__info device__info--2">
          <p class="text-uppercase mb-2">iqos MULTI</p>
          <router-link :to="{name: 'iqos-multi'}" class="btn btn--primary btn--lg">Alege</router-link>
        </div>
      </div>
      <div class="device device--3" @click="redirect('iqos-2-4')">
        <img class="device__img device__img--3" src="@/assets/chose/device-3.png" />
        <div class="device__info device__info--3">
          <p class="text-uppercase mb-2">iqos 2.4 +</p>
          <router-link :to="{name: 'iqos-2-4'}" class="btn btn--primary btn--lg">Alege</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/views/Partials/Header";
import { mapActions } from "vuex";

export default {
  name: "ChoseDevice",
  components: {
    Header
  },
  methods: {
    redirect: function(text) {
      this.$router.push({
        path: "/alege-dispozitiv/" + text
      });
    },
    ...mapActions({
      endPageZero: "endPageZero"
    })
  },
  mounted: function() {
    this.endPageZero();
  }
};
</script>
